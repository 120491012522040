import {
    IonBackButton,
    IonButtons,
    IonCol,
    IonContent,
    IonHeader,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import './About.css';
import {useTranslation} from "react-i18next";

const About: React.FC = () => {
    const {t, i18n} = useTranslation();
    return (
        <IonPage>
            <IonHeader className="ion-no-border">

                <IonToolbar color={"primary"} className={"title"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="home"/>
                    </IonButtons>
                    <IonTitle className={"title"}>{t('about-titulo')}</IonTitle>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen className={"about-background"}>
                <img src="assets/images/Fondo_diamantes_blanco.png" className="fondo-description" alt=""/>
                <div className={"about-data-container"}>
                    <div className={"description-image-container"}>
                        <img className={"description-image"} src="assets/images/logo_alargado.png"/>
                    </div>
                    <div>
                        <div className={"about-info-style"}>
                            {t('about-text1')}
                        </div>
                        <div className={"about-info-style2"}>
                            {t('about-text2')}
                        </div>

                        <div>
                            <IonTitle className={"team-title"}>
                                <img src="assets/images/diamante_Azul.png" className="diamante"/> {t('about-text3')}</IonTitle>
                            <IonCol>
                                <IonRow className={"team-name"}>Manuela Álvarez Jurado (UCO)</IonRow>
                                <IonRow className={"team-name"}>Aurora Ruiz
                                    Mezcua (UCO)</IonRow>
                                <IonRow className={"team-name"}>Enrique Yeguas
                                    Bolívar (UCO)</IonRow>
                                <IonRow className={"team-name"}>Jesús de Manuel
                                    Jerez (UGR)</IonRow>
                                <IonRow className={"team-name"}>Estefanía Flores
                                    Acuña (UMA)</IonRow>
                                <IonRow className={"team-name"}>Bachir Mahyub
                                    Rayaa (UGR)</IonRow>
                            </IonCol>
                        </div>
                        <div>
                            <IonTitle className={"team-title"}>
                                <img src="assets/images/diamante_Azul.png" className="diamante"/>{t('about-text4')}</IonTitle>
                            <IonCol>
                                <IonRow className={"team-name"}>Pedro Mogorrón (UA)</IonRow>
                                <IonRow className={"team-name"}>Chelo Vargas Sierra (UA)</IonRow>
                                <IonRow className={"team-name"}>María García
                                    Antuñana (US)</IonRow>
                                <IonRow className={"team-name"}>Gisella Policastro
                                    (UCO)</IonRow>
                                <IonRow className={"team-name"}>Francisco Luque
                                    Janodet (UCO)</IonRow>
                                <IonRow className={"team-name"}>Sabah El-Herch
                                    (UCO)</IonRow>
                                <IonRow className={"team-name"}>Hasna Karim
                                    (Universidad de
                                    Casablanca,
                                    Marruecos)</IonRow>
                                <IonRow className={"team-name"}>José Antonio
                                    Ruíz Sánchez
                                    (UCO)</IonRow>
                                <IonRow className={"team-name"}>Manuel Gómez
                                    (UCO)</IonRow>
                                <IonRow className={"team-name"}>Sergio Sepúlveda
                                    (Cámara de
                                    Comercio)</IonRow>
                                <IonRow className={"team-name"}>Pedro Garrido
                                    (UGR, CH de la
                                    UCO)</IonRow>
                                <IonRow className={"team-name"}>Antonio
                                    Rodríguez (UCO)</IonRow>
                            </IonCol>
                        </div>

                    </div>
                    <div className={"about-info-style3"}>{t('about-text5')}
                    </div>
                </div>

            </IonContent>
        </IonPage>
    );
};

export default About;
