import {
    IonButtons, IonCard, IonBackButton,
    IonContent,
    IonHeader,
    IonPage, IonRow,
    IonTitle,
    IonToolbar, IonText, IonIcon, IonButton
} from '@ionic/react';
import './Contact.css';
import {t} from "i18next";
import {useTranslation} from "react-i18next";
import {logoFacebook, logoInstagram, logoTwitter, searchOutline} from "ionicons/icons";
import React from "react";


const Contact: React.FC = () => {
    const {t, i18n} = useTranslation();
    return (

        <IonPage>
            <IonHeader className="ion-no-border">
                <IonButtons slot="start">
                    <IonBackButton defaultHref="home"/>
                </IonButtons>
                <IonToolbar color={"primary"} className={"title"}>
                    <IonTitle className={"title"}>{t('contact-titulo')}</IonTitle>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen>
                <img src="assets/images/Fondo_diamantes_blanco.png" className="fondo-description"/>
                <div className={"description-image-container"}>
                    <img className={"description-image"} src="assets/images/logo_alargado.png"/>
                </div>
                <IonCard className={"contact-data-container"}>
                    <IonRow>
                        <IonText className={"about-info-style"}>{t('contact-text1')}</IonText>
                        <IonText className={"about-info-style"}>
                            Manuela Álvarez Jurado y Aurora Ruiz Mezcua <br></br>
                            Facultad de Filosofía y Letras, plaza del cardenal Salazar s/n<br></br>
                            14071 Córdoba (SPAIN)
                        </IonText>
                    </IonRow>
                    <IonRow>
                        <a className={"icon-size"} href={"https://twitter.com/Bijoulex_"} target={"_blank"}><IonIcon className={"icon-size"} icon={logoTwitter}></IonIcon></a>
                        <a href={"https://www.facebook.com/bijoulex"} target={"_blank"}><IonIcon className={"icon-size"} icon={logoFacebook}></IonIcon></a>
                        <a href={"https://www.instagram.com/bijoulex"} target={"_blank"}> <IonIcon className={"icon-size"} icon={logoInstagram}></IonIcon></a>
                    </IonRow>
                </IonCard>

            </IonContent>
        </IonPage>
    );
};

export default Contact;
