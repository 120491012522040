import {IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import './Description.css';
import {useTranslation} from "react-i18next";

const Description: React.FC = () => {
    const {t, i18n} = useTranslation();
    return (
        <IonPage>
            <IonHeader className="ion-no-border">

                <IonToolbar color={"primary"} className={"title"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="home"/>
                    </IonButtons>
                    <IonTitle className={"title"}>{t('description-titulo')}</IonTitle>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen>
                <img src="assets/images/Fondo_diamantes_blanco.png" className="fondo-description" alt=""/>
                <div className={"description-text-container"}>
                    <div className={"description-info-style"}>
                        <b>{t('description-text1')}</b>
                    </div>
                    <div className={"description-image-container"}>
                        <img className={"description-image"} src="assets/images/logo_alargado.png" alt=""/>
                    </div>

                    <div className={"description-info-style1"}>
                        <b>{t('description-text2')}</b>
                    </div>
                    <div className={"description-info-style2"}>
                        {t('description-text3')}
                    </div>
                    <div className={"description-info-style3"}>
                        {t('description-text4')}
                    </div>

                    <div className={"description-info-style4"}>
                        {t('description-text5')}
                    </div>
                </div>

            </IonContent>
        </IonPage>
    );
};

export default Description;
