import {IonContent,IonButton, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle,} from '@ionic/react';

import {useLocation} from 'react-router-dom';
import {
    add,
    home,
    informationCircleOutline, list,
    logIn, logoFacebook, logoInstagram, logoTwitter,
    logOut,
    mailOutline, newspaperOutline,
    schoolOutline,
    searchOutline
} from 'ionicons/icons';
import './Menu.css';
import {useEffect, useState} from "react";
import {logout, profile} from "../auth";
import i18n, {t} from "i18next";
import {useTranslation} from "react-i18next";

const changeLanguage = (id:string) => {
    i18n.changeLanguage(id);
};

const appPages: any[] = [

    {
        title: 'Home',
        url: '/home',
        iosIcon: home,
        mdIcon: home
    },
    {
        title: 'Herramienta',
        url: '/search',
        iosIcon: searchOutline,
        mdIcon: searchOutline
    },
    {
        title: 'Descripción del proyecto',
        url: '/description',
        iosIcon: informationCircleOutline,
        mdIcon: informationCircleOutline
    },
    /*  {
        title: 'Destinatarios',
        url: '/Recipients',
        iosIcon: peopleOutline,
        mdIcon: peopleOutline
      },*/
    {
        title: 'Quienes somos',
        url: '/about',
        iosIcon: schoolOutline,
        mdIcon: schoolOutline
    },
    {
        title: 'Transferencia y divulgación',
        url: '/press',
        iosIcon: newspaperOutline,
        mdIcon: newspaperOutline
    },
    {
        title: 'Contacto',
        url: '/contact',
        iosIcon: mailOutline,
        mdIcon: mailOutline
    },
    {
        title: 'Iniciar sesión',
        url: '/login',
        iosIcon: logIn,
        mdIcon: logIn,
        hideLoged: true
    }
    ,
    {
        title: 'Cerrar sesión',
        iosIcon: logOut,
        mdIcon: logOut,
        hideNotLoged: true,
        clickFun: () => {
            logout()
        }
    }
    ,
    {
        title: 'Crear término',
        url: '/create',
        iosIcon: add,
        mdIcon: add,
        hideNotLoged: true
    },
    {
        title: 'Administrar términos',
        url: '/terms',
        iosIcon: list,
        mdIcon: list,
        hideNotLoged: true
    }
];


const Menu: React.FC = () => {
    const {t, i18n} = useTranslation();
    const location = useLocation();
    const [userProfile, setUserProfile] = useState<any>(null);

    useEffect(() => {
        profile.subscribe((data) => setUserProfile(data));
    }, []);

    return (
        <IonMenu className={"menu-container"} contentId="main" type="overlay">
            <IonContent>
                <IonList id="inbox-list">
                    <IonListHeader>
                        <img src="assets/images/loading1.png" className="logo-menu" alt=''/>
                    </IonListHeader>
                    {appPages.map((appPage, index) => {
                        if (appPage.hideLoged !== undefined && userProfile) return '';
                        if (appPage.hideNotLoged !== undefined && !userProfile) return '';
                        return (
                            <IonMenuToggle key={index} autoHide={false}>
                                <IonItem
                                    className={location.pathname === appPage.url ? 'selected menu-item' : 'menu-item'}
                                    routerLink={appPage.url} routerDirection="none" lines="none" detail={false}
                                    onClick={appPage.clickFun ? appPage.clickFun : null}>
                                    <IonIcon className={"menu-label-icon"} slot="start" ios={appPage.iosIcon}
                                             md={appPage.mdIcon}/>
                                    <IonLabel className={"menu-label-text"}>{t(appPage.title)}</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                        );
                    })}

                </IonList>
                <div className={"language-button-container"}>
                    <IonButton className={"language-button-style"} fill={"clear"} onClick={()=>changeLanguage('en-GB')}>Inglés</IonButton>
                    <IonButton className={"language-button-style"} fill={"clear"} onClick={()=>changeLanguage('es-ES')}>Español</IonButton>
                </div>
                <div className={"language-button-container"}>
                    <IonButton className={"language-button-style"} fill={"clear"} onClick={()=>changeLanguage('it-IT')}>Italiano</IonButton>
                    <IonButton className={"language-button-style"} fill={"clear"} onClick={()=>changeLanguage('fr')}>Francés</IonButton>
                </div>
                <div className={"language-button-container"}>
                    <IonButton className={"language-button-style"} fill={"clear"} onClick={()=>changeLanguage('ar-AE')}>Árabe</IonButton>
                </div>

                <div className={"icons-rrss"} >
                    <a href={"https://mobile.twitter.com/Bijoulex_/"} target={"_blank"}><IonIcon className={"icons-rrss-color"} ios={logoTwitter}></IonIcon></a>
                    <a href={"https://www.facebook.com/bijoulex/?locale=hi_IN"} target={"_blank"}> <IonIcon className={"icons-rrss-color"} ios={logoFacebook}></IonIcon></a>
                    <a href={"https://www.instagram.com/bijoulex/"} target={"_blank"}> <IonIcon className={"icons-rrss-color"}  ios={logoInstagram}></IonIcon></a>
                </div>


            </IonContent>
        </IonMenu>
    );
};

export default Menu;
