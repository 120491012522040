import {
    IonButton, IonCard, IonCardContent, IonCardHeader,
    IonContent, IonInput, IonItem, IonLabel, IonLoading,
    IonPage
} from '@ionic/react';
import './Login.css';
import React, {useState} from "react";
import {loginEmail} from "../auth";
import {t} from "i18next";

const Login: React.FC<any> = ({history}) => {

    const [data, setData] = useState<any>({});
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [showError, setShowError] = useState<any>(null);

    const loginWithEmail = async () => {
        setShowLoading(true);
        try {
            await loginEmail(data['email'], data['password']);
            history.replace('/Home')

        } catch (e) {
            setShowError(t('login-error'))
        }
        setShowLoading(false);
    }

    return (
        <IonPage>
            <IonLoading isOpen={showLoading}/>
            <IonContent fullscreen>
                <div className="background">
                    <IonCard>
                        <IonCardHeader>
                            <img src="assets/images/logo_alargado.png" className="login-logo" alt='Bijoulex'/>
                        </IonCardHeader>
                        <IonCardContent>
                            <IonItem>
                                <IonLabel position="floating">
                                    {t('login-email')}
                                </IonLabel>
                                <IonInput value={data['email']}
                                          onIonChange={(e) => setData({...data, email: e.detail.value!})}>

                                </IonInput>
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating">
                                    {t('login-password')}
                                </IonLabel>
                                <IonInput value={data['password']} type="password"
                                          onIonChange={(e) => setData({...data, password: e.detail.value!})}>
                                </IonInput>
                            </IonItem>
                            <IonButton className={"login-button"} onClick={()=>loginWithEmail()}> {t('login-boton')}</IonButton>
                            <div className={"forgot-container"}>
                                <a  href={'/recover'}> {t('login-olvide')}</a>
                            </div>
                            {showError && <div className={"error-container"}>
                                {showError}
                            </div>}
                        </IonCardContent>
                    </IonCard>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Login;
