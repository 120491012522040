import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonHeader,
    IonIcon,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSearchbar,
    IonToolbar,
    useIonAlert
} from '@ionic/react';
import './Search.css';
import React, {useEffect, useState} from "react";
import {eye, pencil, searchOutline, trashBin} from "ionicons/icons";
import {httpsCallable} from "firebase/functions";
import {db, functions} from "../firebase";
import {collection, deleteDoc, doc, getDoc, updateDoc} from "firebase/firestore/lite";
import {useTranslation} from "react-i18next";

const search = httpsCallable(functions, 'search');
const getTerms = httpsCallable(functions, 'getTerms');

const Home: React.FC<any> = ({match, history}) => {

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [results, setResults] = useState<any>(null);
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [recentlyDeleted, setRecentlyDeleted] = useState<string>('');

    const doSearch = async () => {
        setShowLoading(true);
        const result = await search({term: searchTerm, lan: 'es'});
        setResults(result.data);
        setShowLoading(false);
    }

    useEffect(() => {
        setShowLoading(true);
        getTerms().then(results => {
            setResults(results.data);
            setShowLoading(false);
        });
    }, []);

    const [presentAlert] = useIonAlert();
    const doDelete = async (result: any) => {
        presentAlert({
            backdropDismiss: false,
            header: '¿Estás seguro que deseas eliminar el término ' + result['_source']['es']['termino'] + '?',
            buttons: [
                {
                    text: 'No',
                    handler: () => {
                    },
                },
                {
                    text: 'Si',
                    handler: async () => {
                        setShowLoading(true);
                        const deleteData = await getDoc(doc(collection(db, 'terminos'), result['_id']));
                        for (const term of deleteData.data()?.['relacionados'] || []) {
                            const targetDoc = await getDoc(doc(db, "terminos", term.id));
                            const targetData = targetDoc.data() || {};
                            targetData['relacionados'] = targetData['relacionados'].filter((t: any) => t.id !== result['_id']);
                            await updateDoc(doc(db, "terminos", term.id), {
                                relacionados: targetData['relacionados']
                            });
                        }
                        await deleteDoc(doc(collection(db, 'terminos'), result['_id']));
                        setRecentlyDeleted(result['_id'])
                        setShowLoading(false);
                    },
                },
            ],
        })
    }

    return (
        <IonPage>
            <IonLoading isOpen={showLoading}></IonLoading>
            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>

                    <IonRow className={"searchbar-background"}>
                        <IonCol className={"searchbar-container"} size={"11"}>
                            <IonSearchbar className={"searchbar"} debounce={0}
                                          onIonChange={e => setSearchTerm(e.detail.value!)}
                                          enterkeyhint={"enter"} onKeyPress={e => {
                                if (e.key === 'Enter') doSearch()
                            }}
                                          value={searchTerm}></IonSearchbar>
                        </IonCol>
                        <IonCol className={"searchbar-container"} size={"1"}>
                            <IonButton color={"secondary"} onClick={doSearch}><IonIcon
                                icon={searchOutline}></IonIcon></IonButton>
                        </IonCol>
                    </IonRow>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>

                {results !== null && results.map((result: any) => {
                    if (recentlyDeleted === result['_id']) return '';
                    const date = (new Date(result['_source']['ts']));
                    return <IonCard key={result['_id']}>
                        <IonCardHeader>
                            <IonCardTitle>{result['_source']['es']['termino']}</IonCardTitle>
                            <IonCardSubtitle>{result['_source']['es']['categoria']}</IonCardSubtitle>
                        </IonCardHeader>

                        <IonCardContent>
                            <div className={"admin-content-container"}>
                                <div>
                                    Última
                                    edición: {date.toLocaleDateString() + ' ' + date.toLocaleTimeString()} por {result['_source']['autor']}
                                </div>
                                <div>
                                    <IonButton routerLink={'/term/' + result['_id']}><IonIcon
                                        icon={eye}></IonIcon></IonButton>
                                    <IonButton routerLink={'/edit/' + result['_id']}><IonIcon
                                        icon={pencil}></IonIcon></IonButton>
                                    <IonButton onClick={() => doDelete(result)}><IonIcon
                                        icon={trashBin}></IonIcon></IonButton>
                                </div>
                            </div>
                        </IonCardContent>
                    </IonCard>
                })}
                {results !== null && results.length === 0 &&
                    <div className={"no-results"}>No se encontraron resultados</div>}
            </IonContent>
        </IonPage>
    );
};

export default Home;
