import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonLoading,
    IonPage,
    IonSearchbar,
    IonTextarea,
    IonThumbnail,
    IonTitle,
    IonToolbar,
    useIonAlert
} from '@ionic/react';
import React, {useEffect, useRef, useState} from 'react';
import {nanoid} from "nanoid";
import './Create.css';
import {db, functions, storage} from "../firebase";
import {addDoc, arrayUnion, collection, doc, getDoc, serverTimestamp, updateDoc} from 'firebase/firestore/lite';
import {getDownloadURL, ref, uploadBytes} from 'firebase/storage';
import {profile} from "../auth";
import {httpsCallable} from "firebase/functions";
import {trash} from "ionicons/icons";
import {useParams} from 'react-router-dom';

const idiomas: any = ['es', 'en', 'ar', 'it', 'fr'];

const suggest = httpsCallable(functions, 'suggestTerm');
let originalData: any;
const Create: React.FC<any> = ({}) => {
    const [data, setData] = useState<any>({'es': {}, 'en': {}, 'ar': {}, 'it': {}, 'fr': {}});
    const [suggestions, setSuggestions] = useState<any>([]);
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const imageRef = useRef<any>(null);
    const simboloRef = useRef<any>(null);
    const pronunciacionRef: any = {
        es: useRef<HTMLInputElement>(null),
        en: useRef<HTMLInputElement>(null),
        ar: useRef<HTMLInputElement>(null),
        it: useRef<HTMLInputElement>(null),
        fr: useRef<HTMLInputElement>(null)
    };
    const sbRef = useRef<any>(null);
    const firestoreCollection = collection(db, 'terminos');

    const [idioma, setIdioma] = useState<string>('es');
    const [presentAlert] = useIonAlert();

    const [userProfile, setUserProfile] = useState<any>(null);
    const [editId, setEditId] = useState<any>(null);

    const [show, setShow] = useState<any>({'simbolo': true, 'imagen': true, 'pronunciacion': true});
    const {id} = useParams<any>();
    useEffect(() => {

        profile.subscribe((data) => setUserProfile(data));
        if (id) {
            setShowLoading(true);
            setEditId(id);
            getDoc(doc(db, "terminos", id)).then((termino) => {
                setData(termino.data());
                originalData = termino.data();
                setShowLoading(false);
            });
        }
    }, [id]);

    async function saveData() {
        setShowLoading(true);
        data['autor'] = userProfile['email'];
        data['ts'] = serverTimestamp();
        if (imageRef.current && (imageRef.current.files || []).length > 0) {
            const file = imageRef.current.files[0];
            const storageRef = ref(storage, 'imagenes/' + nanoid() + '_' + file.name);
            await uploadBytes(storageRef, file);
            data['imagen'] = await getDownloadURL(storageRef);
        }
        if (simboloRef.current && (simboloRef.current.files || []).length > 0) {
            const file = simboloRef.current.files[0];
            const storageRef = ref(storage, 'simbolos/' + nanoid() + '_' + file.name);
            await uploadBytes(storageRef, file);
            data['simbolo'] = await getDownloadURL(storageRef);
        }
        for (let id of idiomas) {
            if (pronunciacionRef[id].current && (pronunciacionRef[id].current.files || []).length > 0) {
                const file = pronunciacionRef[id].current.files[0];
                const storageRef = ref(storage, 'pronunciacion/' + nanoid() + '_' + file.name);
                await uploadBytes(storageRef, file);
                data[id]['pronunciacion'] = await getDownloadURL(storageRef);
            }
        }
        if (editId) {
            await updateDoc(doc(db, "terminos", editId), data);
            const toAdd = (data['relacionados'] || []).filter((term: any) => !(originalData['relacionados'] || []).find((t: any) => t.id === term.id));
            const toRemove = (originalData['relacionados'] || []).filter((term: any) => !(data['relacionados'] || []).find((t: any) => t.id === term.id));
            for (const term of toAdd) {
                await updateDoc(doc(db, "terminos", term.id), {
                    relacionados: arrayUnion({
                        id: editId,
                        en: data['en']['termino'] || '',
                        es: data['es']['termino'] || '',
                        ar: data['ar']['termino'] || '',
                        it: data['it']['termino'] || '',
                        fr: data['fr']['termino'] || '',
                    })
                });
            }
            for (const term of toRemove) {
                const targetDoc = await getDoc(doc(db, "terminos", term.id));
                const targetData = targetDoc.data() || {};
                targetData['relacionados'] = targetData['relacionados'].filter((t: any) => t.id !== editId);
                await updateDoc(doc(db, "terminos", term.id), {
                    relacionados: targetData['relacionados']
                });
            }
            presentAlert({
                backdropDismiss: false,
                header: 'Término editado correctamente',
                buttons: [
                    {
                        text: 'Ok',
                    },
                ],
            });
        } else {
            const added = await addDoc(firestoreCollection, data);
            for (const term of (data['relacionados'] || [])) {
                await updateDoc(doc(db, "terminos", term.id), {
                    relacionados: arrayUnion({
                        id: added.id,
                        en: data['en']['termino'] || '',
                        es: data['es']['termino'] || '',
                        ar: data['ar']['termino'] || '',
                        it: data['it']['termino'] || '',
                        fr: data['fr']['termino'] || '',
                    })
                });
            }
            presentAlert({
                backdropDismiss: false,
                header: 'Término creado correctamente. ¿Deseas crear otro término?',
                buttons: [
                    {
                        text: 'No',
                        handler: () => {
                            window.location.href = '/home';
                        },
                    },
                    {
                        text: 'Si',
                        handler: () => {
                            window.location.reload();
                        },
                    },
                ],
            })
        }
        setShowLoading(false);
    }

    const changeIdioma = (idioma: string) => {
        setIdioma(idioma);
    };

    const changeData = (field: string, value: any) => {
        if(value !== undefined) {
            setData({...data, [idioma]: {...data[idioma], [field]: value}});
        }
    };

    const addTerm = (term: any) => {
        sbRef.current.value = '';
        setSuggestions([]);
        const relacionados = data['relacionados'] || [];
        relacionados.push({
            id: term['_id'],
            es: term['_source'].es?.termino || '',
            fr: term['_source'].fr?.termino || '',
            ar: term['_source'].ar?.termino || '',
            it: term['_source'].it?.termino || '',
            en: term['_source'].en?.termino || ''
        });
        setData({...data, relacionados});
    };
    const handleSuggestions = async (ev: any) => {
        if (ev.detail.value.length < 3) {
            setSuggestions([])
        } else {
            const suggestions: any = await suggest({query: ev.detail.value});
            setSuggestions((suggestions.data || []).filter((term: any) => !(data['relacionados'] || []).map((item: any) => item.id).includes(term['_id'])));
        }
    };

    const removeRelacionado = (id: string) => {
        const relacionados = data['relacionados'].filter((term: any) => term.id !== id);
        setData({...data, relacionados});
    };
    return (
        <IonPage>
            <IonHeader className="ion-no-border">
                <IonButtons slot="start">
                    <IonBackButton color="danger" defaultHref="home"/>
                </IonButtons>
                <IonToolbar color={"primary"} className={"title"}>
                    <IonTitle className={"title"}>{editId ? 'Editar término' : 'Crear término'}</IonTitle>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen>
                <IonLoading isOpen={showLoading}></IonLoading>
                <IonCard className={"create-data-container"}>
                    <IonItem lines="none">
                        <IonButton fill={idioma === 'es' ? 'solid' : 'outline'}
                                   onClick={() => changeIdioma('es')}>Español</IonButton>
                        <IonButton fill={idioma === 'en' ? 'solid' : 'outline'}
                                   onClick={() => changeIdioma('en')}>Inglés</IonButton>
                        <IonButton fill={idioma === 'ar' ? 'solid' : 'outline'}
                                   onClick={() => changeIdioma('ar')}>Árabe</IonButton>
                        <IonButton fill={idioma === 'it' ? 'solid' : 'outline'}
                                   onClick={() => changeIdioma('it')}>Italiano</IonButton>
                        <IonButton fill={idioma === 'fr' ? 'solid' : 'outline'}
                                   onClick={() => changeIdioma('fr')}>Francés</IonButton>
                    </IonItem>

                    <IonItem lines="none">
                        <IonLabel position="floating">Término</IonLabel>
                        <IonInput value={data[idioma]['termino']}
                                  onIonChange={(e) => changeData('termino', e.detail.value)}></IonInput>
                    </IonItem>
                    <IonItem lines="none">
                        <IonLabel position="floating">Definición</IonLabel>
                        <IonTextarea value={data[idioma]['definicion']}
                                     onIonChange={(e) => changeData('definicion', e.detail.value)}></IonTextarea>
                    </IonItem>
                    <IonItem lines="none">
                        <IonLabel position="floating">Categoría gramatical</IonLabel>
                        <IonInput value={data[idioma]['categoria']}
                                  onIonChange={(e) => changeData('categoria', e.detail.value)}></IonInput>
                    </IonItem>
                    <IonItem lines="none">
                        <IonLabel position="floating">Bibliografía</IonLabel>
                        <IonInput value={data[idioma]['bibliografia']}
                                  onIonChange={(e) => changeData('bibliografia', e.detail.value)}></IonInput>
                    </IonItem>
                    <IonItem lines="none">
                        <IonLabel position="floating">Contexto</IonLabel>
                        <IonInput value={data[idioma]['contexto']}
                                  onIonChange={(e) => changeData('contexto', e.detail.value)}></IonInput>
                    </IonItem>
                    <IonItem lines="none">
                        <IonLabel slot="start">Pronunciación</IonLabel>
                        <label htmlFor="soundFile"></label>

                        <input className="input" type="file" accept="audio/*" ref={pronunciacionRef['es']}
                               style={{display: idioma === 'es' ? 'block' : 'none'}}
                               onChange={(e) => {
                                   setShow({...show, pronunciacion: false})
                               }}
                        />

                        <input className="input" type="file" accept="audio/*" ref={pronunciacionRef['en']}
                               style={{display: idioma === 'en' ? 'block' : 'none'}}
                               onChange={(e) => {
                                   setShow({...show, pronunciacion: false})
                               }}
                        />

                        <input className="input" type="file" accept="audio/*" ref={pronunciacionRef['ar']}
                               style={{display: idioma === 'ar' ? 'block' : 'none'}}
                               onChange={(e) => {
                                   setShow({...show, pronunciacion: false})
                               }}
                        />

                        <input className="input" type="file" accept="audio/*" ref={pronunciacionRef['it']}
                               style={{display: idioma === 'it' ? 'block' : 'none'}}
                               onChange={(e) => {
                                   setShow({...show, pronunciacion: false})
                               }}
                        />

                        <input className="input" type="file" accept="audio/*" ref={pronunciacionRef['fr']}
                               style={{display: idioma === 'fr' ? 'block' : 'none'}}
                               onChange={(e) => {
                                   setShow({...show, pronunciacion: false})
                               }}
                        />
                    </IonItem>
                    {data[idioma]['pronunciacion'] && show['pronunciacion'] === 0 &&
                        <audio controls>
                            <source src={data[idioma]['pronunciacion']}></source>
                        </audio>}
                    <IonItem lines="none">
                        <IonLabel slot="start">Imagen</IonLabel>
                        <label htmlFor="fileImage"> </label>
                        <input className="input" type="file" id="fileImage" accept="image/*"
                               ref={imageRef}
                               onChange={(e) => {
                                   setShow({...show, imagen: false})
                               }}
                        />
                        {data['imagen'] && show['imagen'] && <IonThumbnail>
                            <img alt="" src={data['imagen']}/>
                        </IonThumbnail>}
                    </IonItem>
                    <IonItem lines="none">
                        <IonLabel position="floating">Fuente imagen</IonLabel>
                        <IonInput value={data['fuenteImagen']}
                                  onIonChange={(e) => setData({
                                      ...data,
                                      fuenteImagen: e.detail.value!
                                  })}></IonInput>
                    </IonItem>
                    <IonItem lines="none">
                        <IonLabel slot="start">Símbolo</IonLabel>
                        <label htmlFor="fileSimbolo"> </label>
                        <input className="input" type="file" id="fileSimbolo" accept="image/*"
                               onChange={(e) => {
                                   setShow({...show, simbolo: false})
                               }}
                               ref={simboloRef}
                        />
                        {data['simbolo'] && show['simbolo'] && <IonThumbnail>
                            <img alt="" src={data['simbolo']}/>
                        </IonThumbnail>}
                    </IonItem>
                    <IonItem lines="none">
                        <IonLabel position="floating">Video</IonLabel>
                        <IonInput placeholder={"Introduce la url del video"} value={data['video']}
                                  onIonChange={(e) => setData({
                                      ...data,
                                      video: e.detail.value!
                                  })}></IonInput>
                    </IonItem>
                    {data['video'] && <iframe width="560" height="315"
                                              src={"https://www.youtube.com/embed/" + (new URL(data['video'])).searchParams.get("v")}
                                              title="YouTube video player" frameBorder="0"
                                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                              allowFullScreen></iframe>}
                    <IonItem lines="none">
                        <IonLabel position="floating">Fuente video</IonLabel>
                        <IonInput value={data['fuenteVideo']}
                                  onIonChange={(e) => setData({
                                      ...data,
                                      fuenteVideo: e.detail.value!
                                  })}></IonInput>
                    </IonItem>

                    <div>
                        <IonLabel>Términos relacionados</IonLabel>
                        <IonCard>
                            <IonSearchbar ref={sbRef} debounce={500}
                                          onIonChange={(ev) => handleSuggestions(ev)}></IonSearchbar>
                            <IonList>
                                {suggestions.length > 0 && <IonListHeader key={'s'}>Sugerencias</IonListHeader>}
                                {suggestions.map((suggestion: any) => <IonItem key={suggestion['_id']}
                                                                               style={{cursor: 'pointer'}}
                                                                               onClick={() => addTerm(suggestion)}>
                                    {suggestion['_source']['es']['termino']}
                                </IonItem>)}
                            </IonList>
                        </IonCard>
                    </div>
                    <IonList>
                        {data['relacionados'] && data['relacionados'].length > 0 &&
                            <IonListHeader key={'a'}>Añadidos</IonListHeader>}
                        {(data['relacionados'] || []).map((relacionado: any) => <IonItem key={relacionado['id']}>
                            {relacionado['es']}
                            <IonButton slot={'end'} onClick={() => removeRelacionado(relacionado['id'])}><IonIcon
                                icon={trash}></IonIcon></IonButton>
                        </IonItem>)}
                    </IonList>
                    <IonButton onClick={saveData} expand="full" disabled={!data['es']['termino']}>Guardar</IonButton>
                </IonCard>

            </IonContent>
        </IonPage>
    );
};

export default Create;
