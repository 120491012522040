import {
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonRow,
    IonToolbar
} from '@ionic/react';
import './Home.css';
import {useTranslation} from "react-i18next";

const Home: React.FC = () => {

    const {t, i18n} = useTranslation();
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <img src="assets/images/Fondo_diamantes_blanco.png" className="fondo-home" alt="Logo Bijoulex"/>
                <div className={"logo-home-container"}>
                    <img src="assets/images/logo_alargado.png" className="logo-home" alt="Logo Bijoulex"/>
                </div>
                <div className={"explanation-container"}>
                    <div className={"explanation-text"}> {t('home-text1')}
                    </div>
                </div>

                <div className={"collaborators-container"}>
                    <div className={"collaborators-text"}> {t('home-text2')}
                    </div>
                </div>
                <IonGrid>
                    <IonRow className={"logos-container"}>
                        <IonCol className={"logos-container"}><img className={"logo-collaborator"} alt=""
                                                                   src="assets/images/uco.png"/></IonCol>
                        <IonCol className={"logos-container"}><img className={"logo-collaborator"} alt=""
                                                                   src="assets/images/catedra_joyeria.jpg"/></IonCol>

                    </IonRow>
                    <IonRow className={"logos-container"}>
                        <IonCol className={"logos-container"}><img className={"logo-collaborator2"} alt=""
                                                                   src="assets/images/CRN.jpg"/></IonCol>
                    </IonRow>
                </IonGrid>


            </IonContent>
        </IonPage>
    );
};

export default Home;
