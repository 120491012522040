import {initializeApp} from 'firebase/app';
import {getFirestore} from 'firebase/firestore/lite';
import { getStorage } from "firebase/storage";
import {getAuth} from "firebase/auth";
import {getFunctions} from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyA40AdHx_P9WDTLyIvhCDMI08vlFrpKUeg",
    authDomain: "bijoulex.firebaseapp.com",
    projectId: "bijoulex",
    storageBucket: "bijoulex.appspot.com",
    messagingSenderId: "1005332361544",
    appId: "1:1005332361544:web:36d1231859ca04b253437d"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

export const storage = getStorage(app);
export const auth = getAuth(app);
export const functions = getFunctions(app, 'europe-west1');

