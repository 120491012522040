import {
    IonBackButton,
    IonButtons,
    IonCol,
    IonContent,
    IonHeader,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import './Press.css';
import {useTranslation} from "react-i18next";
import {t} from "i18next";

const press: React.FC = () => {

    return (
        <IonPage>
            <IonHeader className="ion-no-border">

                <IonToolbar color={"primary"} className={"title"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="home"/>
                    </IonButtons>
                    <IonTitle className={"title"}>{t('press-titulo')}</IonTitle>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen>
                <div className={"press-text-container"}>
                    <div className={"press-image-container"}>
                        <img className={"press-image"} src="assets/images/logo_alargado.png" alt=""/>
                    </div>

                    <div className={"press-info-style1-title"}>
                        <b>1. Transferencia</b>
                    </div>
                    <div className={"press-info-style1-subtitle"}>
                        Monográficos en revistas
                    </div>
                    <div className={"press-info-style1"}>
                        <ul>
                            <li>
                                Onomázein. Revista de lingüística, filología y traducción (incluir el logo de la
                                revista: <a href={"http://onomazein.letras.uc.cl/index.php/onom"} target='_blank'>http://onomazein.letras.uc.cl/index.php/onom).</a><br/>
                                <b>Título del monográfico:</b><br/>
                                «Enfoques profesionales de la traducción: aspectos terminológicos para el sector de la
                                joyería y orfebrería»
                            </li>
                            <li>
                                ELUA: Estudios de Lingüística. Universidad de Alicante. <a href={"http://onomazein.letras.uc.cl/index.php/onom"} target='_blank'>http://onomazein.letras.uc.cl/index.php/onom).</a><br/>
                                <b>Título del monográfico:</b><br/>
                                «La terminología técnica multilingüe: gestión del
                                conocimiento y de recursos lingüísticos en sectores industriales: la joyería»
                            </li>
                        </ul>
                    </div>

                    <div className={"press-info-style1-subtitle"}>
                        Capítulos de libro
                    </div>
                    <div className={"press-info-style1"}>
                        <ul>
                            <li>
                                Trinado Jiménez, C.: “Modelos de relaciones conceptuales para la traducción al
                                francés del patrimonio orfebre español” en <b>Álvarez Jurado, M.</b> Policastro Ponce:
                                Traducción, Discurso turístico y Cultura, Editorial Comares (en prensa), 2023.
                            </li>
                            <li>
                                Trinado Jiménez, C.Policastro Ponce, G. <b>Álvarez Jurado, M.</b>:”La traducción en el
                                sector de la joyería a través de la Lingüística de corpus en francés y en español”,
                                Nuevas teorías y aproximaciones a estudios sobre lengua, lingüística y traducción”,
                                Dykinson (en prensa) 2023.
                            </li>
                            <li>
                                Trinado Jiménez, C.:”Fraseología y léxico de la orfebrería en contextos turísticos
                                (francés-español), Nuevos enfoques lingüísticos y traductológicos del discurso
                                turístico, John Benjamins, (en prensa) 2023.
                            </li>
                        </ul>
                    </div>
                    <div className={"press-info-style2-title"}>
                        <b>2. Prensa</b>
                    </div>
                    <div>
                        <ul>
                            <li className={"press-info-style2-subtitle "}><a href={"https://www.uco.es/servicios/actualidad/institucional/item/146030-las-investigadoras-\n" +
                            "principales-del-proyecto-bijoulex-se-reunen-la-delegada-de-empleo-y-\n" +
                            "transformacion-\n" +
                            "economica-y-el-director-de-la-escuela-de-joyeria"}>Grupo Duplex</a></li>
                            <li className={"press-info-style2-subtitle "}><a href={"https://www.grupoduplex.com/el-crn-celebra-el-proyecto-bijoulex/"}>Grupo Duplex</a></li>
                            <li className={"press-info-style2-subtitle "}><a href={"https://diariojoya.com/superar-la-barrera-del-idioma-clave-para-el-comercio-exterior-de-los-fabricantes-espanoles/"}>Diario Joya y Bijoulex</a></li>
                            <li className={"press-info-style2-subtitle "}><a href={"https://diariojoya.com/rocio-blanco-el-intercambio-entre-administracion-y-empresa-es-clave-para-alcanzar-objetivos-comunes/"}>Diario Joya y Bijoulex</a></li>
                            <li className={"press-info-style2-subtitle "}><a href={"https://www.europapress.es/esandalucia/cordoba/noticia-centro-referencia-nacional-\n" +
                            "joyeria-cordoba-reune-universidades-participantes-proyecto-bijoulex-\n" +
                            "20220715134040.html"}>Europapress EsAndalucía</a></li>

                            <li className={"press-info-style2-subtitle "}><a href={"https://crnandalucia.com/el-crn-escuela-de-joyeria-del-sae-participa-en-el-proyecto-\n" +
                            "bijoulex/"}>CRN Andalucía</a></li>

                            <li className={"press-info-style2-subtitle "}><a href={"https://www.eldiadecordoba.es/cordoba/joyeria-Cordoba-proyecto-unificar-\n" +
                            "terminos_0_1702030601.html"}>Diario Córdoba</a></li>

                            <li className={"press-info-style2-subtitle "}><a href={"https://www.diariodesevilla.es/tag/joyeria_cordoba/amp/"}>Diario Córdoba</a></li>

                            <li className={"press-info-style2-subtitle "}><a href={"https://www.lavanguardia.com/local/sevilla/20220616/8344635/crn-escuela-joyeria-\n" +
                            "sae-participa-proyecto-bijoulex-universidad-cordoba.html"}>La Vanguardia</a></li>

                            <li className={"press-info-style2-subtitle "}><a href={"https://www.malagahoy.es/tag/joyeria_cordoba/"}>Málaga hoy</a></li>

                            <li className={"press-info-style2-subtitle "}><a href={"https://www.ceia3.es/servicios/actualidad/institucional/itemlist/tag/Proyecto%20BIJO\n" +
                            "ULEX"}>CeiA3</a></li>

                        </ul>

                    </div>
                    <div className={"press-info-style3-title"}>
                        <b>2. Eventos y divulgación</b>
                    </div>

                    <ul>
                        <li>
                            <div className={"press-info-style1-subtitle"}>
                            3.º Congreso Internacional de Traducción e Interpretación de Lenguas Ibéricas
                            «TransIbérica». Noviembre de 2022
                        </div>
                            <div className={"press-info-style1"}>
                                <a href={"https://sites.google.com/site/congresotransiberica/res%C3%BAmenes-\n" +
                                "resumos/presentaci%C3%B3n-de-proyectos-de-investigaci%C3%B3n/manuela-\n" +
                                "%C3%A1lvarez-jurado-aurora-ruiz-mezcua-gisella-policastro-ponce"}>Ver información</a>
                            </div>
                        </li>
                        <li>
                            <div className={"press-info-style1-subtitle"}>
                                VIII Coloquio Lucentino (Universidad de Alicante)
                            </div>
                            <div className={"press-info-style1"}>
                                <a href={"https://dti.ua.es/es/coloquio-lucentino/viii-\n" +
                                "coloquio-lucentino.-la-traduccion-de-las-referencias-referentes-culturales-\n" +
                                "transversalidad-y-nuevas-tendencias.html))"}>Ver información</a>
                            </div>
                        </li>
                        <li>
                            <div className={"press-info-style1-subtitle"}>
                                Mesa Redonda: La filología y el mundo empresarial: del odio al amor. Feria de
                                Empleo 2022. Facultad de Filología de la Universidad de Sevilla.
                            </div>
                            <div className={"press-info-style1"}>
                                <img src="assets/images/mesaredonda.jpeg" className=""/>
                                <img src="assets/images/cartel2.png" className=""/>
                            </div>
                        </li>
                        <li>
                            <div className={"press-info-style1-subtitle"}>
                                III CONGRESO INTERNACIONAL NODOS DEL CONOCIMIENTO. La
                                academia frente a los retos de la humanidad. Innovación, investigación y
                                transferencias ante el horizonte 2030
                            </div>
                            <div className={"press-info-style1"}>
                                <a>Tipo de comunicación: “Aproximación a la traducción en el sector de la joyería a
                                    través de la Lingüística de de corpus”.</a>
                            </div>
                        </li>
                        <li>
                            <div className={"press-info-style1-subtitle"}>
                                Jornadas Formativas con la Escuela de Joyería de Córdoba
                            </div>
                            <div className={"press-info-style1"}>
                                <img src="assets/images/Cartel.jpeg" className=""/>
                            </div>
                        </li>
                    </ul>
                </div>

            </IonContent>
        </IonPage>
    );
};

export default press;
