import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import es from './languages/es.json';
import it from './languages/it.json';
import en from './languages/en.json';
import fr from './languages/fr.json';
import ar from './languages/ar.json';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {'es-ES': {translation: es}, 'en-GB': {translation: en}, 'fr': {translation: fr}, 'ar-AE': {translation: ar}, 'it-IT': {translation: it }}
i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'es',
        debug: false,
        returnObjects: true,

        interpolation: {
            escapeValue: false,
        }
    });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
