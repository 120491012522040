import {
    IonButtons, IonCard, IonBackButton,
    IonContent,
    IonHeader,
    IonPage, IonRow,
    IonTitle,
    IonToolbar, IonText
} from '@ionic/react';
import './Recipients.css';

const Recipients: React.FC = () => {


    return (
        <IonPage>
            <IonHeader className="ion-no-border">
                <IonToolbar color={"primary"} className={"title"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="home"/>
                    </IonButtons>
                    <IonTitle className={"title"}>Destinatarios</IonTitle>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen className={"recipients-background"}>
                <IonCard className={"recipients-data-container"}>
                    <IonRow>
                   <IonText className={"recipients-info-style"}>
                       Los principales receptores de este producto son empresas públicas y privadas, autónomos, joyeros y
                       orfebres, traductores e intérpretes, especialistas en idiomas, profesores, agentes comerciales, personal
                       de marketing y exportación/importación de productos, etc. <br></br>Asimismo, estaría destinada también a otros
                       trabajadores de los sectores implicados o interesados, pues se trata de una herramienta abierta a la
                       ciudadanía. Su uso se centra en poner la puesta en alza de los productos de joyería y subsidiarios
                       producidos en esta región desde una perspectiva original, multimodal, multilingüe y multidisciplinar.
                   </IonText>
                    </IonRow>




                </IonCard>

            </IonContent>
        </IonPage>
    );
};

export default Recipients;
