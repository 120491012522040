import {IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import {Route} from 'react-router-dom';
import Menu from './components/Menu';
import Home from './pages/Home';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Create from "./pages/Create";
import Login from "./pages/Login";
import Recipients from "./pages/Recipients";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Description from "./pages/Description";
import Search from "./pages/Search";
import Recover from "./pages/Recover";
import Termino from "./pages/Termino";
import Terms from "./pages/Terms";
import Press from "./pages/Press";

setupIonicReact();

const App: React.FC = () => {
    return (
        <IonApp>
            <IonReactRouter>
                <IonSplitPane contentId="main">

                    <Menu/>
                    <IonRouterOutlet id="main">
                        <Route path="" component={Home} exact/>
                        <Route path="/search" component={Search} exact/>
                        <Route path="/search/:termId/:lanId" component={Search} exact/>
                        <Route path="/home" component={Home} exact/>
                        <Route path="/create" component={Create} exact/>
                        <Route path="/edit/:id" component={Create} exact/>
                        <Route path="/terms" component={Terms} exact/>
                        <Route path="/login" component={Login} exact/>
                        {/*<Route path="/result" component={Result} exact/>*/}
                        {/*<Route path="/pendienteIt" component={PendienteIt} exact/>*/}
                        {/*<Route path="/pendienteFr" component={PendienteFr} exact/>*/}
                        {/*<Route path="/anillos" component={Anillos} exact/>*/}
                        {/*<Route path="/anillosIt" component={AnillosIt} exact/>*/}
                        {/*<Route path="/anillosFr" component={AnillosFr} exact/>*/}
                        {/*<Route path="/alianza" component={Alianza} exact/>*/}
                        <Route path="/term/:termId" component={Termino} exact/>
                        {/*<Route path="/alianzaIt" component={AlianzaIt} exact/>*/}
                        {/*<Route path="/alianzaFr" component={AlianzaFr} exact/>*/}
                        <Route path="/recipients" component={Recipients} exact/>
                        <Route path="/about" component={About} exact/>
                        <Route path="/contact" component={Contact} exact/>
                        <Route path="/description" component={Description} exact/>
                        <Route path="/recover" component={Recover} exact/>
                        <Route path="/press" component={Press} exact/>
                    </IonRouterOutlet>
                </IonSplitPane>
            </IonReactRouter>
        </IonApp>
    );
};

export default App;
