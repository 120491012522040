import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonHeader,
    IonIcon,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSearchbar,
    IonSelect,
    IonSelectOption,
    IonToolbar
} from '@ionic/react';
import './Search.css';
import React, {useEffect, useState} from "react";
import {searchOutline} from "ionicons/icons";
import {httpsCallable} from "firebase/functions";
import {functions} from "../firebase";
import {useParams} from "react-router-dom";

const search = httpsCallable(functions, 'search');

const Home: React.FC<any> = ({history}) => {

    const [searchLn, setSearchLn] = useState<string>('es');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [results, setResults] = useState<any>(null);
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const {termId, lanId} = useParams<any>();

    const doSearch = async (term: string, lan: string) => {
        setShowLoading(true);
        const result = await search({term: term, lan: lan});
        setResults(result.data);
        setShowLoading(false);
    }

    const updateAndSearch = async () => {
        if (searchTerm !== '') {
            history.push('/search/' + searchTerm + '/' + searchLn);
            doSearch(searchTerm, searchLn);
        }
    };

    useEffect(() => {
        if (termId && lanId) {
            setSearchTerm(termId);
            setSearchLn(lanId)
            doSearch(termId, lanId);
        } else {
            setShowLoading(true);
            search().then(results => {
                setResults(results.data);
                setShowLoading(false);
            });
        }
    }, [termId, lanId]);
    return (
        <IonPage>
            <IonLoading isOpen={showLoading}></IonLoading>
            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>

                    <IonRow className={"searchbar-background"}>
                        <IonCol className={"searchbar-container"} size={"8"}>
                            <IonSearchbar className={"searchbar"} debounce={0}
                                          onIonChange={e => setSearchTerm(e.detail.value!)}
                                          enterkeyhint={"enter"} onKeyPress={e => {
                                if (e.key === 'Enter') updateAndSearch()
                            }}
                                          value={searchTerm}></IonSearchbar>
                        </IonCol>
                        <IonCol className={"searchbar-container"} size={"3"}>
                            <IonSelect className={"select-background"} interface="popover" placeholder="Seleccionar"
                                       value={searchLn} onIonChange={e => {
                                setSearchTerm('');
                                setResults(null);
                                setSearchLn(e?.detail?.value)
                            }}>
                                <IonSelectOption value="es">Español</IonSelectOption>
                                <IonSelectOption value="en">Inglés</IonSelectOption>
                                <IonSelectOption value="fr">Francés</IonSelectOption>
                                <IonSelectOption value="it">Italiano</IonSelectOption>
                                <IonSelectOption value="ar">Árabe</IonSelectOption>
                            </IonSelect>
                        </IonCol>
                        <IonCol className={"searchbar-container"} size={"1"}>
                            <IonButton color={"secondary"} onClick={updateAndSearch}><IonIcon
                                icon={searchOutline}></IonIcon></IonButton>
                        </IonCol>
                    </IonRow>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>

                {results !== null && results.map((result: any) => <IonCard routerLink={'/term/' + result['_id']}
                                                                           key={result['_id']}>
                    <IonCardHeader>
                        <IonCardTitle>{result['_source'][searchLn]['termino']}</IonCardTitle>
                        <IonCardSubtitle>{result['_source'][searchLn]['categoria']}</IonCardSubtitle>
                    </IonCardHeader>

                    <IonCardContent>
                        <div className={"content-container"}>
                            {result['_source']['imagen'] &&
                                <div className={"preview-img"}><img alt="" src={result['_source']['imagen']}/>
                                </div>}
                            <div className={"highlighted"}>
                                {(result['highlight'] && result['highlight'][searchLn + '.termino.text'] || []).map((highlight: string, index: number) =>
                                    <div key={'t' + index}>Término: <span
                                        dangerouslySetInnerHTML={{'__html': highlight}}/>
                                    </div>)}
                                {result['highlight'] && (result['highlight'][searchLn + '.descripcion.text'] || []).map((highlight: string, index: number) =>
                                    <div key={'d' + index}>...<span
                                        dangerouslySetInnerHTML={{'__html': highlight}}/>...
                                    </div>)}
                                {result['highlight'] && (result['highlight'][searchLn + '.contexto.text'] || []).map((highlight: string, index: number) =>
                                    <div key={'c' + index}>...<span
                                        dangerouslySetInnerHTML={{'__html': highlight}}/>...
                                    </div>)}
                                {!result['highlight'] && <div>{result['_source'][searchLn]['definicion']}</div>}
                            </div>
                        </div>
                    </IonCardContent>
                </IonCard>)}
                {results !== null && results.length === 0 &&
                    <div className={"no-results"}>No se encontraron resultados</div>}
            </IonContent>
        </IonPage>
    );
};

export default Home;
