import { signInWithEmailAndPassword, sendPasswordResetEmail, signOut} from "firebase/auth";
import {auth, db} from "./firebase";
import {BehaviorSubject} from 'rxjs';
import {collection, getDoc, doc} from "firebase/firestore/lite";
export const profile = new BehaviorSubject(undefined)
const users = collection(db, 'users')

auth.onAuthStateChanged(async userAuth => {
    if (userAuth) {
        const userDB = await getDoc(doc(users,  userAuth.uid))
        const user = {uid: userAuth.uid}
        if (userDB.exists) {
            profile.next({...user, ...userDB.data()});
        } else {
            profile.next(user);
        }

    } else {
        profile.next(userAuth);
    }
});


export const checkUserId = async userAuth =>{
    if(userAuth){
        const userId = await getDoc(doc(users,  userAuth.uid))
        return userId;
    }

}

export const logout = async () => {
    await signOut(auth);
};


export const loginEmail = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
}

export const recoverPass = (email) => {
    return sendPasswordResetEmail(auth, email);
}

