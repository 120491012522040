import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonToast
} from '@ionic/react';
import './Recover.css';
import React, {useState} from "react";
import 'firebase/firestore'
import 'firebase/auth'
import {recoverPass} from "../auth";


const Recover: React.FC<any> = ({history}) => {

    const [data, setData] = useState<any>({});
    const [showToast, setShowToast] = useState(false);

    const sendRecoverPass = () => {
        recoverPass(data['email']);
        setShowToast(true);
    };


    return (
        <IonPage>
            <IonContent fullscreen>
                <IonToast
                    isOpen={showToast}
                    onDidDismiss={() => {
                        setShowToast(false);
                        history.push('/login');
                    }}
                    message="Te hemos enviado las instrucciones de recuperación de contraseña al email."
                    duration={2000}
                />
                <div className="background">
                    <IonCard>
                        <IonCardHeader>
                            <img src="assets/images/logo_alargado.png" className="login-logo" alt='Bijoulex'/>
                        </IonCardHeader>
                        <IonCardContent>
                            <div className="contenedor">
                                Introduce tu e-mail y te enviaremos los pasos para recuperar tu contraseña <br/>
                            </div>
                            <div className="contenedor">
                                <IonItem>
                                    <IonLabel position="floating">Email</IonLabel>
                                    <IonInput value={data['email']} onIonChange={(e) => setData({
                                        ...data,
                                        email: e.detail.value!
                                    })}></IonInput>
                                </IonItem>
                            </div>
                            <div className="contenedor">
                                <IonButton expand="block" onClick={sendRecoverPass}>Recuperar contraseña</IonButton>
                            </div>
                        </IonCardContent>
                    </IonCard>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Recover;
