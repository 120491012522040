import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCol,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonPage,
    IonRouterLink,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import './Termino.css';
import React, {useEffect, useState} from "react";
import {doc, getDoc} from "firebase/firestore/lite";
import {db} from "../firebase";
import {t} from "i18next";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

const Alianza: React.FC<any> = ({}) => {
    const {t, i18n} = useTranslation();
    const [idioma, setIdioma] = useState<string>('es');
    const [data, setData] = useState<any>({'es': {}, 'en': {}, 'ar': {}, 'it': {}, 'fr': {}});
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const changeIdioma = (idioma: string) => {
        setIdioma(idioma);
    };
    const getParam = (url: string) => {
        return new URL(url).searchParams.get("v");
    };
    const {termId} = useParams<any>();
    useEffect(() => {

            const getData = async () => {
                setShowLoading(true);
                const term = await getDoc(doc(db, "terminos", termId));
                setData(term.data());
                setShowLoading(false);
            };
            getData();

        }, [termId]
    );
    return (
        <IonPage>
            <IonLoading isOpen={showLoading}/>
            <IonHeader className="ion-no-border">
                <IonToolbar color={"primary"} className={"title"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/search"/>
                    </IonButtons>
                    <IonTitle className={"title"}>{data[idioma]['termino']}</IonTitle>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen className={"result-background"}>
                <IonCard className={"result-data-container"}>
                    <IonRow>
                        <IonCol size={"12"} sizeLg={"4"}>
                            <IonLabel slot="start" position="stacked" className={"label-style"}
                                      color={"primary"}>Imagen</IonLabel>
                            {data['imagen'] && <img alt="" src={data['imagen']} className="login-logo"/>}

                        </IonCol>
                        <IonCol>
                            <div className={"data-container"}>
                                <IonItem>
                                    <IonButton fill={idioma === 'es' ? 'solid' : 'outline'}
                                               onClick={() => changeIdioma('es')}>Español</IonButton>
                                    <IonButton fill={idioma === 'en' ? 'solid' : 'outline'}
                                               onClick={() => changeIdioma('en')}>Inglés</IonButton>
                                    <IonButton fill={idioma === 'ar' ? 'solid' : 'outline'}
                                               onClick={() => changeIdioma('ar')}>Árabe</IonButton>
                                    <IonButton fill={idioma === 'it' ? 'solid' : 'outline'}
                                               onClick={() => changeIdioma('it')}>Italiano</IonButton>
                                    <IonButton fill={idioma === 'fr' ? 'solid' : 'outline'}
                                               onClick={() => changeIdioma('fr')}>Francés</IonButton>
                                </IonItem>
                            </div>
                            <div className={"data-container"}>
                                <IonLabel  className={"label-style"}
                                          color={"primary"}>{t('ficha-termino')}</IonLabel>
                                <IonText className={"term-style"}>{data[idioma]['termino']}</IonText>
                            </div>

                            <div className={"data-container"}>
                                <IonLabel position="stacked" className={"label-style"}
                                          color={"primary"}>{t('ficha-definicion')}</IonLabel>

                                    <IonText
                                             className={"term-style"}>{data[idioma]['definicion']}</IonText>
                            </div>
                        </IonCol>
                    </IonRow>

                    <IonItem lines={"none"}>
                        <IonText className={"label-style"} color={"primary"}>{t('ficha-fuenteimg')}</IonText>
                        <IonText className={"info-style"}>{data['fuenteImagen']}</IonText>
                    </IonItem>
                    <IonItem lines={"none"}>
                        <IonText className={"label-style"} color={"primary"}>{t('ficha-categoriagramatical')}</IonText>
                        <IonText className={"info-style"}>{data[idioma]['categoria']}</IonText>
                    </IonItem>
                    <IonItem lines={"none"}>
                        <IonText className={"label-style"} color={"primary"}>{t('ficha-contexto')}</IonText>
                        <IonText className={"info-style"}>{data[idioma]['contexto']}</IonText>
                    </IonItem>
                    <IonItem lines={"none"}>
                        <IonText className={"label-style"} color={"primary"}>{t('ficha-bibliografia')}</IonText>
                        <IonText className={"info-style"}>{data[idioma]['bibliografia']}</IonText>
                    </IonItem>
                    <IonItem lines={"none"}>
                        <IonText slot="start" className={"label-style"}
                                 color={"primary"}>{t('ficha-pronunciacion')}</IonText>
                        {data[idioma]['pronunciacion'] && <audio controls key={idioma+'-p'}>
                            <source src={data[idioma]['pronunciacion']} key={idioma+'-s'}></source>
                        </audio>}
                    </IonItem>

                    <IonItem lines={"none"}>
                        <IonText slot="start" className={"label-style"} color={"primary"}>{t('ficha-simbolo')}</IonText>
                        {data['simbolo'] && <img alt="" src={data['simbolo']} className="login-logo"/>}
                    </IonItem>
                    <IonItem lines={"none"}>
                        <IonText className={"label-style"} color={"primary"}>{t('ficha-video')}</IonText>
                        {data['video'] && <iframe width="560" height="315"
                                                  src={"https://www.youtube.com/embed/" + getParam(data['video'])}
                                                  title="YouTube video player" frameBorder="0"
                                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                  allowFullScreen></iframe>}
                    </IonItem>

                    <IonItem lines={"none"}>
                        <IonText className={"label-style"} color={"primary"}>{t('ficha-fuentevideo')}</IonText>
                        <IonText className={"info-style"}>{data['fuenteVideo']}</IonText>
                    </IonItem>

                    <IonItem lines={"none"}>
                        <IonText className={"label-style"} color={"primary"}>{t('ficha-terminosrelacionados')}</IonText>
                        {data['relacionados'] && <div>

                            <div className={"related-items"}>
                                {data['relacionados'].map((item: any) => <div key={item['id']}><IonRouterLink
                                    href={"/term/" + item['id']}>{item[idioma]}</IonRouterLink></div>)}
                            </div>
                        </div>}
                    </IonItem>


                </IonCard>

            </IonContent>
        </IonPage>
    );
};

export default Alianza;
